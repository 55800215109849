//
// Color definitions
//

// default colors
$white: #fff;
$gray-100: #f7f6f4;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-350: #eaeaea;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$grays: ();
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
        (
            "100": $gray-100,
            "200": $gray-200,
            "300": $gray-300,
            "400": $gray-400,
            "500": $gray-500,
            "600": $gray-600,
            "700": $gray-700,
            "800": $gray-800,
            "900": $gray-900
        ),
        $grays
);

// project colors
//
// Rijksoverheid Kleuren;
// --------------------------------------------------;

$bruin: #94710a;
$bruin-light: #dfd4b5;
$bruin-lighter: #efeada;

$donkerblauw: #01689b;
$donkerblauw-light: #cce0f1;
$donkerblauw-lighter: #e5f0f9;

$donkerbruin: #673327;
$donkerbruin-light: #d1c1be;
$donkerbruin-lighter: #e8e1df;

$donkergeel: #ffb612;
$donkergeel-light: #ffe9b7;
$donkergeel-lighter: #fff4dc;

$donkergroen: #247632;
$donkergroen-light: #becdc3;
$donkergroen-lighter: #dfe6e1;

$geel: #f9e11e;
$geel-light: #fdf6bb;
$geel-lighter: #fefbdd;

$groen: #38B741;
$groen-light: #c3dbb6;
$groen-lighter: #e1eddb;

$hemelblauw: #007bc7;
$hemelblauw-light: #b2d7ee;
$hemelblauw-lighter: #d9ebf7;

$lichtblauw: #8fcae7;
$lichtblauw-light: #ddeff8;
$lichtblauw-lighter: #eef7fb;

$mintgroen: #76d2b6;
$mintgroen-light: #d6f1e9;
$mintgroen-lighter: #ebf8f4;

$mosgroen: #777c00;
$mosgroen-light: #d6d7b2;
$mosgroen-lighter: #ebebd9;

$oranje: #e17000;
$oranje-light: #f6d4b2;
$oranje-lighter: #fbead9;

$paars: #42145f;
$paars-light: #c6b8cf;
$paars-lighter: #e3dce7;

$robijnrood: #ca005d;
$robijnrood-light: #efb2ce;
$robijnrood-lighter: #f7d9e7;

$rood: #d52b1e;
$rood-light: #f2bfbb;
$rood-lighter: #f9dfdd;

$roze: #f092cd;
$roze-light: #fadef0;
$roze-lighter: #fdeff8;

$violet: #a90061;
$violet-light: #e5b2cf;
$violet-lighter: #f2d9e7;


$main: $geel;
$main-light: $geel-light;
$main-lighter: $geel-lighter;

$grijs-1: #f3f3f3;
$grijs-2: #e6e6e6;
$grijs-3: #cccccc;
$grijs-4: #b4b4b4;
$grijs-5: #999999;
$grijs-6: #696969;
$grijs-7: #535353;

// base colors
$blue: $donkerblauw; // #007bff;
$indigo: #6610f2;
$purple: $paars; // #6f42c1;
$pink: $roze; // #e83e8c;
$red: $rood; // #dc3545;
$orange: $oranje; // #fd7e14;
$yellow: $geel; // #ffc107;
$green: $groen; // #28a745;
$teal: $mintgroen; // #20c997;
$cyan: #17a2b8;


// theme colors
$primary: $blue;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

// stylelint-disable-next-line scss/dollar-variable-default
$colors: (
    // Base colors
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,

    // RO kleuren
    "main": $main,
    "main-light": $main-light,
    "main-lighter": $main-lighter,

    "bruin": $bruin,
    "bruin-light": $bruin-light,
    "bruin-lighter": $bruin-lighter,
    "donkerblauw": $donkerblauw,
    "donkerblauw-light": $donkerblauw-light,
    "donkerblauw-lighter": $donkerblauw-lighter,
    "donkerbruin": $donkerbruin,
    "donkerbruin-light": $donkerbruin-light,
    "donkerbruin-lighter": $donkerbruin-lighter,
    "donkergeel": $donkergeel,
    "donkergeel-light": $donkergeel-light,
    "donkergeel-lighter": $donkergeel-lighter,
    "donkergroen": $donkergroen,
    "donkergroen-light": $donkergroen-light,
    "donkergroen-lighter": $donkergroen-lighter,
    "geel": $geel,
    "geel-light": $geel-light,
    "geel-lighter": $geel-lighter,
    "groen": $groen,
    "groen-light": $groen-light,
    "groen-lighter": $groen-lighter,
    "hemelblauw": $hemelblauw,
    "hemelblauw-light": $hemelblauw-light,
    "hemelblauw-lighter": $hemelblauw-lighter,
    "lichtblauw": $lichtblauw,
    "lichtblauw-light": $lichtblauw-light,
    "lichtblauw-lighter": $lichtblauw-lighter,
    "mintgroen": $mintgroen,
    "mintgroen-light": $mintgroen-light,
    "mintgroen-lighter": $mintgroen-lighter,
    "mosgroen": $mosgroen,
    "mosgroen-light": $mosgroen-light,
    "mosgroen-lighter": $mosgroen-lighter,
    "oranje": $oranje,
    "oranje-light": $oranje-light,
    "oranje-lighter": $oranje-lighter,
    "paars": $paars,
    "paars-light": $paars-light,
    "paars-lighter": $paars-lighter,
    "robijnrood": $robijnrood,
    "robijnrood-light": $robijnrood-light,
    "robijnrood-lighter": $robijnrood-lighter,
    "rood": $rood,
    "rood-light": $rood-light,
    "rood-lighter": $rood-lighter,
    "roze": $roze,
    "roze-light": $roze-light,
    "roze-lighter": $roze-lighter,
    "violet": $violet,
    "violet-light": $violet-light,
    "violet-lighter": $violet-lighter,

    "white": $white,
    "grijs-1": $grijs-1,
    "grijs-2": $grijs-2,
    "grijs-3": $grijs-3,
    "grijs-4": $grijs-4,
    "grijs-5": $grijs-5,
    "grijs-6": $grijs-6,
    "grijs-7": $grijs-7,
    "black": $black,
);