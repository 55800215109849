@include media-breakpoint-down("md") {
  body {
    min-width: map-get($grid-breakpoints, "lg");
  }

  .container {
    min-width: 960px;
  }
}

// Borders
@media (min-width: 768px) {
  .border-between-md > [class*="col-"]:not(:first-child):after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 1px;
    background: $black;
  }
}

.alert {
  > *:last-child {
    margin-bottom: 0;
  }
}

.border-between-2 > [class*="col-"]:not(:first-child):after {
  left: -1px;
  width: 2px;
}

.border-between-3 > [class*="col-"]:not(:first-child):after {
  left: -2px;
  width: 3px;
}

// Main logo
.rws-logo {
  background-image: url("./../../assets/images/logo/IW_RW_Logo_online_banner.svg");

  @include media-breakpoint-up($compatible-breakpoint) {
    background-image: url("./../../assets/images/logo/IW_RW_Logo_online_pos_en.svg");
  }

  background-repeat: no-repeat;
  background-size: auto 115px;
  background-position: center -20px;
  padding-top: 88px;
  position: relative;
  font-size: 18px;

  [lang="nl"] & {
    background-image: url("./../../assets/images/logo/IW_RW_Logo_online_pos_nl.svg");
  }
  [lang="de"] & {
    background-image: url("./../../assets/images/logo/IW_RW_Logo_online_pos_de.svg");
  }
  [lang="fr"] & {
    background-image: url("./../../assets/images/logo/IW_RW_Logo_online_pos_fr.svg");
  }
}

.overview-chart-wrapper {
  position: relative;
  padding: 5rem 0 7rem;
}

.theme-overview-icon {
  position: absolute;
  text-align: center;
}

// Waarde
#theme-overview-icon-1 {
  top: 0;
  left: 163px;

  @include media-breakpoint-up(xl) {
    top: 5px;
    left: 208px;
  }
}

// Commitment en Draagvlak
#theme-overview-icon-2 {
  top: 0;
  right: 90px;

  @include media-breakpoint-up(xl) {
    top: 0;
    right: 160px;
  }
}

// Kosten
#theme-overview-icon-3 {
  bottom: 234px;
  right: 76px;

  @include media-breakpoint-up(xl) {
    bottom: 262px;
    right: 104px;
  }
}

// Inpasbaarheid
#theme-overview-icon-4 {
  bottom: 7px;
  right: 206px;

  @include media-breakpoint-up(xl) {
    bottom: 8px;
    right: 257px;
  }
}

// Risico's
#theme-overview-icon-5 {
  bottom: 129px;
  left: 110px;

  @include media-breakpoint-up(xl) {
    bottom: 153px;
    left: 134px;
  }
}

.site-cover {
  min-height: 340px;
  background-color: #f2f2f2;
}

.site-cover-image-container {
  width: 100%;
}

.site-cover-image {
  width: auto;
  max-height: 440px;
}

.site-cover-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.site-cover-text {
  width: 100%;
}

.is-sticky {
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: white !important;
  padding: 12px 0;
}
