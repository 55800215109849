.legend-item {
    position: relative;
    padding-left: 1.5em;
}

.legend-bullet {
    position: absolute;
    left: 0;
    bottom: 50%;
    width: 1em;
    height: 1em;
    display: inline-block;
    border-radius: 50%;
    transform: translate(0, 50%);
}
