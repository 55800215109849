// Toggles
//
// Used in conjunction with global variables to enable certain theme features.

// Skins
@import "mixins/background-variant";

@function ro-color($color, $key: 'main') {
    @return map-get($color, $key);
}

@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }
    @return $number;
}

@function unit-em($number) {
    $number: strip-unit($number);
    @return $number * 1em;
}

@function unit-rem($number) {
    $number: strip-unit($number);
    @return $number * 1rem;
}

@mixin fa-icon-type($icon:null, $type: null) {
    @include fa-icon;
    @if ($type == 'fab') {
        font-family: 'Font Awesome 5 Brands';
    } @else if ($type == 'fa' or $type == 'fas') {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
    } @else {
        font-family: 'Font Awesome 5 Free';
        font-weight: 400;
    }
    content: fa-content($icon);
}

@mixin button-outline-variant($color, $color-hover: color-yiq($color), $active-background: $color, $active-border: $color) {
    color: $color;
    background: transparent;
    border-color: $color;

    @include hover() {
        color: $color-hover;
        background-color: $active-background;
        border-color: $active-border;
    }

    &:focus,
    &.focus {
        box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
    }

    &.disabled,
    &:disabled {
        color: $color;
        background-color: transparent;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        color: color-yiq($active-background);
        background-color: $active-background;
        border-color: $active-border;

        &:focus {
            @if $enable-shadows and $btn-active-box-shadow != none {
                @include box-shadow($btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($color, .5));
            } @else {
                // Avoid using mixin so we can pass custom focus shadow properly
                box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
            }
        }
    }
}

// Button sizes
@mixin button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
    padding: unit-em($padding-y) unit-em($padding-x);
    font-size: unit-em($font-size);
    line-height: $line-height;
    // Manually declare to provide an override to the browser default
    @include border-radius($border-radius, 0);
}
