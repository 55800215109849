@font-face {
  font-family: "RO Sans";
  src: url('./../../assets/webfonts/ROsanswebtextregular.eot');
  src: url('./../../assets/webfonts/ROsanswebtextregular.eot?#iefix') format('embedded-opentype'),
  url('./../../assets/webfonts/ROsanswebtextregular.woff') format('woff'),
  url('./../../assets/webfonts/ROsanswebtextregular.ttf') format('truetype');

  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "RO Sans";
  src: url('./../../assets/webfonts/ROsanswebtextitalic.eot');
  src: url('./../../assets/webfonts/ROsanswebtextitalic.eot?#iefix') format('embedded-opentype'),
  url('./../../assets/webfonts/ROsanswebtextitalic.woff') format('woff'),
  url('./../../assets/webfonts/ROsanswebtextitalic.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-family: "RO Sans";
  src: url('./../../assets/webfonts/ROsanswebtextbold.eot');
  src: url('./../../assets/webfonts/ROsanswebtextbold.eot?#iefix') format('embedded-opentype'),
  url('./../../assets/webfonts/ROsanswebtextbold.woff') format('woff'),
  url('./../../assets/webfonts/ROsanswebtextbold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "RO Serif";
  src: url('./../../assets/webfonts/ROserifwebregular.eot');
  src: url('./../../assets/webfonts/ROserifwebregular.eot?#iefix') format('embedded-opentype'),
  url('./../../assets/webfonts/ROserifwebregular.woff') format('woff'),
  url('./../../assets/webfonts/ROserifwebregular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "RO Serif";
  src: url('./../../assets/webfonts/ROserifwebitalic.eot');
  src: url('./../../assets/webfonts/ROserifwebitalic.eot?#iefix') format('embedded-opentype'),
  url('./../../assets/webfonts/ROserifwebitalic.woff') format('woff'),
  url('./../../assets/webfonts/ROserifwebitalic.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-family: "RO Serif";
  src: url('./../../assets/webfonts/ROserifwebbold.eot');
  src: url('./../../assets/webfonts/ROserifwebbold.eot?#iefix') format('embedded-opentype'),
  url('./../../assets/webfonts/ROserifwebbold.woff') format('woff'),
  url('./../../assets/webfonts/ROserifwebbold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}