// stylelint-disable selector-no-qualifying-type

//
// Textual form controls
//

input.form-control, select.form-control {
  max-width: 20em;
}

label[for="honeypot"], #honeypot {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

label {
  font-weight: $font-weight-bold;
}

.topic-radio {

  .custom-radio {
    padding: 0;

    .custom-control-label {
      width: 100%;
      height: $line-height-base * 1em;
      cursor: pointer;

      // Background-color and (when enabled) gradient
      &::before {
        height: $line-height-base * 1em;
        left: 0;
        top: 0;
        border-radius: 0;
        width: 100%;
        border: 1px solid rgba($black, .125);
      }

      // Foreground (icon)
      &::after {
        left: 50%;
        top: 50%;
        width: auto;
        height: auto;
        transform: translate(-50%, -50%);
      }
    }


    .custom-control-input {
      // outline: #f9e11e solid 1px;

      &:checked ~ .custom-control-label::before {
        // outline: #39870c solid 1px;
      }

      &:checked ~ .custom-control-label::after {
        // outline: #39870c solid 1px;
        background: none;
        @include fa-icon-type($fa-var-check, 'fas');
      }

      &:focus ~ .custom-control-label::before {
        // outline: #007bc7 solid 1px;

      }

      &:focus:not(:checked) ~ .custom-control-label::before {
        // outline: #e17000 solid 1px;
      }

      &:not(:disabled):active ~ .custom-control-label::before {
        // outline: #42145f solid 1px;

      }

      // Use [disabled] and :disabled to work around https://github.com/twbs/bootstrap/issues/28247
      &[disabled],
      &:disabled {
        ~ .custom-control-label {
          // outline: #d52b1e solid 1px;

          &::before {
            // outline: #a90061 solid 1px;
          }
        }
      }
    }
  }
}


@each $color, $value in $colors {
  .colorSwitch-#{$color} {
    // Background-color and (when enabled) gradient
    .custom-control-label {
      &::before {
        background-color: $value;
        border-color: rgba($black, .125);
      }

      &::after {
        color: color-yiq($value);
      }
    }

    .custom-control-input {
      // outline: #f9e11e solid 1px;

      &:checked ~ .custom-control-label::before {
        // outline: #39870c solid 1px;
        background-color: $value;
        border-color: rgba($black, .125);
      }

      &:checked ~ .custom-control-label::after {
        color: color-yiq($value);
      }

      &:focus ~ .custom-control-label::before {
        // outline: #007bc7 solid 1px;

      }

      &:focus:not(:checked) ~ .custom-control-label::before {
        // outline: #e17000 solid 1px;
      }

      &:not(:disabled):active ~ .custom-control-label::before {
        // outline: #42145f solid 1px;

      }

      // Use [disabled] and :disabled to work around https://github.com/twbs/bootstrap/issues/28247
      &[disabled],
      &:disabled {
        ~ .custom-control-label {
          // outline: #d52b1e solid 1px;

          &::before {
            // outline: #a90061 solid 1px;
          }
        }
      }
    }
  }
}