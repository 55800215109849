// Base class
//
// Kickstart any navigation component with a set of style resets. Works with
// `<nav>`s, `<ul>`s or `<ol>`s.

.nav-overview {
    margin-bottom: $grid-gutter-width;

    .nav-brand {
        color: color-yiq($nav-overview-link-active);
        background-color: $nav-overview-link-active;
    }

    position: relative;

    .nav-item {
        display: flex;
        flex-basis: 0;
        flex-grow: 1;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;

        &:last-child {
            &:before, &:after {
                content: '';
                display: block;
                position: absolute;
                pointer-events: none;
                transition: left #{$nav-overview-transition-speed} ease;
            }

            &:before {
                background: $gray-200;
                bottom: $nav-overview-border-size * -1;
                left: 0;
                height: $nav-overview-border-size;
                width: 100%;
                z-index: 0;
            }

            &:after {
                background: $nav-overview-link-active;
                bottom: $nav-overview-border-size * -1;
                left: 0;
                height: $nav-overview-border-size;
                width: $nav-overview-item-width;
                z-index: 0;
            }


        }
    }

    @for $i from 1 through $nav-overview-menu-items-loop-offset {
        // when the Nav item "is active", make the indicator line up with the navigation item.
        .nav-item:nth-child(#{$i}).active ~ .nav-item:last-child:after {
            left: ($nav-overview-item-width*$i)-$nav-overview-item-width;
        }
    }

    // ======================================================
    // Step 3 - Making it move
    // ======================================================

    // This could be in the same loop as the above but for the tutorial steps it's not
    @for $i from 1 through $nav-overview-menu-items-loop-offset {
        // We have to use !important to make the hovers overide if the last:child is the active or hovered
        .nav-item:nth-child(#{$i}):hover ~ .nav-item:last-child:after {
            left: ($nav-overview-item-width*$i)-$nav-overview-item-width !important;
        }
    }

    // this could be nested in the original .nav-item
    .nav-item {
        &:last-child {
            // make sure the last-child talks to itself
            &:hover, &.active {
                &:after {
                    left: 100%-$nav-overview-item-width !important;
                }
            }
        }
    }


    .nav-link {
        border-bottom: 5px solid $white;

        .svg-icon {
            margin-right: 0.4em;

            svg path {
                fill: $nav-overview-link-gray;
            }
        }
    }

    .nav-link.active,
    .show > .nav-link {
        &.nav-brand {
            color: color-yiq($nav-overview-link-active);
        }

        color: $nav-overview-link-active;
        font-weight: $font-weight-bold;

        .svg-icon {
            svg path {
                fill: $nav-overview-link-active;
            }
        }

    }
}