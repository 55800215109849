// stylelint-disable declaration-no-important, selector-list-comma-newline-after

//
// Headings
//

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

h1, .h1 {
  @include font-size($h1-font-size);
}

h2, .h2 {
  @include font-size($h2-font-size);
}

h3, .h3 {
  @include font-size($h3-font-size);
  margin-bottom: .66em;
}

h4, .h4 {
  @include font-size($h4-font-size);
  line-height: 1.8;
}

h5, .h5 {
  @include font-size($h5-font-size);
}

h6, .h6 {
  @include font-size($h6-font-size);
}

.site-title {
  @include font-size($h3-font-size);
  font-weight: $font-weight-bold;
  color: $black;
  padding-top: .4em;
  padding-bottom: .2em;
  font-size: 1.111em;
  line-height: 1.3em;
}

.lead {
  @include font-size($lead-font-size);
  font-weight: $lead-font-weight;
}

// Type display classes
.display-1 {
  @include font-size($display1-size);
  font-weight: $display1-weight;
  line-height: $display-line-height;
}

.display-2 {
  @include font-size($display2-size);
  font-weight: $display2-weight;
  line-height: $display-line-height;
}

.display-3 {
  @include font-size($display3-size);
  font-weight: $display3-weight;
  line-height: $display-line-height;
}

.display-4 {
  @include font-size($display4-size);
  font-weight: $display4-weight;
  line-height: $display-line-height;
}


//
// Horizontal rules
//

hr {
  margin-top: $hr-margin-y;
  margin-bottom: $hr-margin-y;
  border: 0;
  border-top: $hr-border-width solid $hr-border-color;
}


//
// Emphasis
//

small,
.small {
  @include font-size($small-font-size);
  font-weight: $font-weight-normal;
}

.smaller {
  @include font-size($smaller-font-size);
}

mark,
.mark {
  padding: $mark-padding;
  background-color: $mark-bg;
}


//
// Lists
//
ul, ol {
  li {
    margin-bottom: 3px;

    ol,
    ul {
      margin-bottom: 0;
      font-size: 1em;
    }
  }

}

ul:not(.list-unstyled):not(.list-group):not(.nav) {
  @include list-unstyled();
  margin-left: 1em;

  > li {
    &:before {
      content: "›";
      float: left;
      margin-left: -1em;
    }
  }
}

.list-group-item {
  & + & {
    border-top-width: $list-group-border-width !important;
  }
}

ul.list-group,
ol.list-group {
  margin-left: 0;

  > li.list-group-item {
    a {
      @extend .stretched-link;
    }

    &:before {
      @include fa-icon-type($fa-var-angle-right, 'fas');
      color: $blue;
      position: absolute;
      right: $list-group-item-padding-x;
      top: 50%;
      transform: translate(0, -50%);
    }
  }
}

.list-unstyled {
  @include list-unstyled();
}

// Inline turns list items into inline-block
.list-inline {
  @include list-unstyled();
}

.list-inline-item {
  display: inline-block;

  &:not(:last-child) {
    margin-right: $list-inline-padding;
  }
}


//
// Misc
//

// Builds on `abbr`
.initialism {
  @include font-size(90%);
  text-transform: uppercase;
}

// Blockquotes
.blockquote {
  margin-bottom: $spacer;
  @include font-size($blockquote-font-size);
}

.blockquote-footer {
  display: block;
  @include font-size($blockquote-small-font-size);
  color: $blockquote-small-color;

  &::before {
    content: "\2014\00A0"; // em dash, nbsp
  }
}


.footer {
  position: relative;
  margin-top: 3em;
  border-top: 2px solid $grijs-7;
  background-color: $footer-bg;
  color: $footer-color;
  box-shadow: inset $box-shadow;
  padding: 3em 0;

  &:before {
    width: 45px;
    height: 24px;
    content: '';
    background-color: #154273;
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
  }

  .lead {
    @include font-size($h3-font-size);
    font-style: italic;
  }

  a {
    color: $footer-color;
    text-decoration: none;
    background-color: transparent; // Remove the gray background on active links in IE 10.

    @include hover() {
      color: $footer-color;
      text-decoration: underline;
    }
  }


  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    color: $footer-color;
    position: relative;
    font-weight: $font-weight-normal;
    margin-left: ($grid-gutter-width / 2)*-1;
    margin-right: ($grid-gutter-width / 2)*-1;
    padding-left: ($grid-gutter-width / 2);
    padding-right: ($grid-gutter-width / 2);
    padding-bottom: $headings-margin-bottom / 1.5;

    &:after {
      position: absolute;
      left: 0;
      bottom: 0;
      border-bottom: 2px solid $grijs-3;
      content: '';
      width: 100%;
    }
  }

  p, ul, ol {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.external-link {
  &:after {
    font-size: 0.75em;
    @include fa-icon-type($fa-var-external-link-alt, 'fas');
    margin-left: 0.256em;
  }
}