.svg-icon {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;

    svg {
        height: 1em;
        vertical-align: middle;
    }

    &.svg-icon-fluid {
        svg {
            max-width: 100%;
            width: 100%;
            height: auto;
        }
    }
}

@each $color, $value in $theme-colors {
    .icon-#{$color} {
        svg path {
            fill: $value;
        }
    }
}
